import * as tslib_1 from "tslib";
import { AuthGuard } from './auth.guard';
import { isFunction, isObject } from '../utils';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
var UserGuard = /** @class */ (function (_super) {
    tslib_1.__extends(UserGuard, _super);
    function UserGuard(router, authService) {
        return _super.call(this, router, authService) || this;
    }
    UserGuard.prototype.getUserMatcher = function (route) {
        if (isObject(route.data.authUserMatcher)) {
            return function (user) { return Object.keys(route.data.authUserMatcher)
                .every(function (key) { return user[key] === route.data.authUserMatcher[key]; }); };
        }
        if (isFunction(route.data.authUserMatcher)) {
            return route.data.authUserMatcher;
        }
    };
    UserGuard.prototype.check = function (route, state) {
        var _this = this;
        return this.authService
            .user
            .pipe(map(function (user) {
            var matcher = _this.getUserMatcher(route);
            return matcher ? matcher(user) : !!user;
        }), catchError(function () { return of(false); }));
    };
    return UserGuard;
}(AuthGuard));
export { UserGuard };
