import * as tslib_1 from "tslib";
import { AuthGuard } from './auth.guard';
var GuestGuard = /** @class */ (function (_super) {
    tslib_1.__extends(GuestGuard, _super);
    function GuestGuard(router, authService) {
        return _super.call(this, router, authService) || this;
    }
    GuestGuard.prototype.check = function (route, state) {
        return this.authService.guest();
    };
    return GuestGuard;
}(AuthGuard));
export { GuestGuard };
