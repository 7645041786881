import * as tslib_1 from "tslib";
import { BaseModel } from '../../common/model/base.model';
import { Property } from '../../common/model/property.decorator';
var CurrencyModel = /** @class */ (function (_super) {
    tslib_1.__extends(CurrencyModel, _super);
    function CurrencyModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    tslib_1.__decorate([
        Property()
    ], CurrencyModel.prototype, "id", void 0);
    tslib_1.__decorate([
        Property()
    ], CurrencyModel.prototype, "name", void 0);
    tslib_1.__decorate([
        Property({
            sourceName: 'short_name'
        })
    ], CurrencyModel.prototype, "shortName", void 0);
    tslib_1.__decorate([
        Property()
    ], CurrencyModel.prototype, "selected", void 0);
    return CurrencyModel;
}(BaseModel));
export { CurrencyModel };
