import * as i0 from "@angular/core";
import * as i1 from "../api/api-resources.service";
var UserService = /** @class */ (function () {
    function UserService(apiResourcesService) {
        this.apiResourcesService = apiResourcesService;
    }
    UserService.prototype.getUser = function (id) {
        return this.apiResourcesService.staff.find(id);
    };
    UserService.prototype.getUsers = function () {
        return this.apiResourcesService.staff.get();
    };
    UserService.prototype.createMasterUser = function (data) {
        return this.apiResourcesService.masterUser.create(data);
    };
    UserService.prototype.createUser = function (data) {
        return this.apiResourcesService.staff.create(data);
    };
    UserService.prototype.updateUser = function (data) {
        return this.apiResourcesService.auth.user.update(data);
    };
    UserService.prototype.deleteUser = function (id) {
        return this.apiResourcesService.staff.delete(id);
    };
    UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.ApiResourcesService)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
