import * as i from 'inflect';
import { isConstructor, isPresent, valueToType } from '../utils';
import { factory as createFactory } from '../factory';
export function propertyFactoryGetter(property) {
    return "get" + i.camelize(i.underscore(property)) + "Factory";
}
export function Property(options) {
    return function (target, property) {
        var _a = Object.assign({
            get: 'get',
            set: 'set',
            sourceName: property
        }, options), get = _a.get, set = _a.set, sourceName = _a.sourceName, typeOrFactory = _a.typeOrFactory, isArray = _a.isArray;
        if (isPresent(typeOrFactory)) {
            Object.defineProperty(target, propertyFactoryGetter(property), {
                get: function () {
                    var factory = isConstructor(typeOrFactory)
                        ? createFactory(function (value) { return valueToType(value, typeOrFactory); })
                        : typeOrFactory;
                    return isArray
                        ? function (value) { return Array.isArray(value) && value.length ? value.map(function (item) { return factory.create(item); }) : []; }
                        : function (value) { return factory.create(value); };
                },
                enumerable: false,
                configurable: true
            });
        }
        // @todo this set getter/setter as enumerable only on class, not on instance
        Object.defineProperty(target, property, {
            get: function () {
                return this[get](sourceName);
            },
            set: function (value) {
                this[set](sourceName, value);
            },
            enumerable: true,
            configurable: true
        });
    };
}
