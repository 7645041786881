import { Routes } from '@angular/router';
import { UsersComponent } from './users.component';
import { UserGuard } from '../../common/auth/user.guard';
import { DashboardComponent } from '../user/dashboard.component';
import { UserRole } from '../models/user.model';
import { roleMatcher } from '../auth/role.matcher';
import { homeOrLogin } from '../auth/home-or-login';
var ɵ0 = {
    authUserMatcher: roleMatcher(UserRole.MasterUser),
    authFallbackUrl: homeOrLogin
};
var routes = [
    {
        path: 'users',
        component: DashboardComponent,
        canActivate: [UserGuard],
        data: ɵ0,
        children: [
            {
                path: '',
                component: UsersComponent,
            },
        ]
    }
];
var UsersRoutingModule = /** @class */ (function () {
    function UsersRoutingModule() {
    }
    return UsersRoutingModule;
}());
export { UsersRoutingModule };
export { ɵ0 };
