import { ApiResource } from './api.resource';
import { AccessModel } from '../models/access.model';
import { ResourceAction } from '../../common/http/types';
import { UserModel } from '../models/user.model';
import { CompanyModel } from '../models/company.model';
import { CurrencyModel } from '../models/currency.model';
import { AccountModel } from '../models/account.model';
import { ReportModel, TotalReportModel } from '../models/report.model';
var ApiResourcesService = /** @class */ (function () {
    function ApiResourcesService(apiService) {
        this.apiService = apiService;
    }
    Object.defineProperty(ApiResourcesService.prototype, "auth", {
        get: function () {
            var apiService = this.apiService;
            var prefix = 'auth';
            return {
                get access() {
                    return new ApiResource(apiService, prefix + "/access", AccessModel).when(ResourceAction.Delete, function (self) { return self.authorize(); });
                },
                get user() {
                    return new ApiResource(apiService, prefix + "/user", UserModel).when([ResourceAction.Get, ResourceAction.Update], function (self) { return self.authorize(); });
                }
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApiResourcesService.prototype, "authAdmin", {
        get: function () {
            var adminPrefix = 'admin';
            var apiService = this.apiService;
            return {
                get access() {
                    return new ApiResource(apiService, adminPrefix + "/access", AccessModel).when(ResourceAction.Delete, function (self) { return self.authorize(); });
                },
                get user() {
                    return new ApiResource(apiService, adminPrefix + "/user", UserModel).when([
                        ResourceAction.Get,
                        ResourceAction.Delete,
                        ResourceAction.Create,
                        ResourceAction.Update
                    ], function (self) { return self.authorize(); });
                },
                get restore() {
                    return new ApiResource(apiService, "admin/user/restore", UserModel).authorize();
                }
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApiResourcesService.prototype, "adminStaffList", {
        get: function () {
            return new ApiResource(this.apiService, "admin/staff/list", UserModel).authorize();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApiResourcesService.prototype, "adminCompanyList", {
        get: function () {
            return new ApiResource(this.apiService, "admin/company/list", CompanyModel).authorize();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApiResourcesService.prototype, "company", {
        get: function () {
            return new ApiResource(this.apiService, "company", CompanyModel).authorize();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApiResourcesService.prototype, "masterUser", {
        get: function () {
            return new ApiResource(this.apiService, "master", UserModel).authorize();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApiResourcesService.prototype, "staff", {
        get: function () {
            return new ApiResource(this.apiService, "staff", UserModel).authorize();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApiResourcesService.prototype, "account", {
        get: function () {
            return new ApiResource(this.apiService, "account", AccountModel).authorize();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApiResourcesService.prototype, "exportAccounts", {
        get: function () {
            return new ApiResource(this.apiService, "account/export/xlsx", AccountModel).authorize();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApiResourcesService.prototype, "currency", {
        get: function () {
            return new ApiResource(this.apiService, "currency", CurrencyModel).authorize();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApiResourcesService.prototype, "currencyRates", {
        get: function () {
            return new ApiResource(this.apiService, "/currency/rates", CurrencyModel).authorize();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApiResourcesService.prototype, "selectedCurrency", {
        get: function () {
            return new ApiResource(this.apiService, "user/currency/selected", CurrencyModel).authorize();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApiResourcesService.prototype, "setActiveCurrency", {
        get: function () {
            return new ApiResource(this.apiService, "user/currency/select", CurrencyModel).authorize();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApiResourcesService.prototype, "userCompanies", {
        get: function () {
            return new ApiResource(this.apiService, "user/companies", CompanyModel).authorize();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApiResourcesService.prototype, "report", {
        get: function () {
            return new ApiResource(this.apiService, "report", ReportModel).authorize();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApiResourcesService.prototype, "reportShow", {
        get: function () {
            return new ApiResource(this.apiService, "report/show", ReportModel).authorize();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApiResourcesService.prototype, "reportAnnual", {
        get: function () {
            return new ApiResource(this.apiService, "report/annual", TotalReportModel).authorize();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApiResourcesService.prototype, "reportBalance", {
        get: function () {
            return new ApiResource(this.apiService, "report/balance", ReportModel).authorize();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApiResourcesService.prototype, "reportSummaryRecord", {
        get: function () {
            return new ApiResource(this.apiService, "report/summary", TotalReportModel).authorize();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApiResourcesService.prototype, "reportAdjustment", {
        get: function () {
            return new ApiResource(this.apiService, "report/adjustment", ReportModel).authorize();
        },
        enumerable: true,
        configurable: true
    });
    return ApiResourcesService;
}());
export { ApiResourcesService };
