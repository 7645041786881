import * as UrlPattern from 'url-pattern';
import { ResourceAction, ResponseType } from './types';
import { map } from 'rxjs/operators';
import { isObject, value } from '../utils';
var Resource = /** @class */ (function () {
    function Resource(client, name, parents) {
        if (parents === void 0) { parents = []; }
        this.client = client;
        this.name = name;
        this.parents = parents;
        this.actionScopes = {};
        this.params = {};
        this.responseType = ResponseType.Json;
        this.queryNames = {
            skip: 'skip',
            take: 'take',
            page: 'page',
            pageSize: 'pageSize',
            order: 'order'
        };
    }
    Object.defineProperty(Resource.prototype, "idName", {
        get: function () {
            return 'id';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Resource.prototype, "pathPattern", {
        get: function () {
            return new UrlPattern("/" + this.name + "(/:" + this.idName + ")(:subPath)");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Resource.prototype, "fabricator", {
        get: function () {
            return function (v) { return v; };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Resource.prototype, "actionParams", {
        get: function () {
            var _this = this;
            if (!Object.keys(this.params).length) {
                return {};
            }
            var pathParamNames = this.pathPattern.names;
            return Object.keys(this.params)
                .reduce(function (params, name) {
                var group = pathParamNames.includes(name) ? 'path' : 'query';
                params[group] = params[group] || {};
                params[group][name] = _this.params[name];
                return params;
            }, {});
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Resource.prototype, "hasParents", {
        get: function () {
            return !!this.parents.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Resource.prototype, "path", {
        get: function () {
            var path = this.actionParams.path;
            return this.buildPath(path);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Resource.prototype, "url", {
        get: function () {
            var params = this.actionParams;
            return this.client.url(this.buildPath(params.path), params.query);
        },
        enumerable: true,
        configurable: true
    });
    Resource.prototype.buildPath = function (params) {
        if (this.hasParents) {
            return this.parents
                .slice()
                .reverse()
                .reduce(function (path, resource) {
                return resource.where('subPath', path).path;
            }, this.pathPattern.stringify(params));
        }
        return this.pathPattern.stringify(params);
    };
    Resource.prototype.applyActionScopes = function (action) {
        var _this = this;
        if (Array.isArray(this.actionScopes[action])) {
            this.actionScopes[action].forEach(function (scope) { return scope(_this); });
            delete this.actionScopes[action];
        }
    };
    Resource.prototype.isPaginated = function (response) {
        return isObject(response) && ['data', 'links', 'meta'].every(function (key) { return response.hasOwnProperty(key); });
    };
    Resource.prototype.paginate = function (response) {
        var _this = this;
        return {
            data: response.data.map(function (item) { return _this.fabricator(item); }),
            meta: {
                last_page: response.meta.last_page,
                current_page: response.meta.current_page,
                per_page: response.meta.per_page,
                total: response.meta.total,
            }
        };
    };
    Resource.prototype.useHeader = function (header, headerValue) {
        var _this = this;
        this.headers = this.headers || {};
        if (isObject(header)) {
            Object.keys(header)
                .forEach(function (name) { return _this.useHeader(name, header[name]); });
        }
        else {
            this.headers[header] = headerValue;
        }
        return this;
    };
    Resource.prototype.useAuthBearer = function (token) {
        return this.useHeader('Authorization', "Bearer " + value(token));
    };
    Resource.prototype.for = function () {
        var parents = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            parents[_i] = arguments[_i];
        }
        this.parents.every(function (resource, i) {
            if (parents[i]) {
                resource.whereId(parents[i]);
                return true;
            }
            return false;
        });
        return this;
    };
    Resource.prototype.when = function (action, scope) {
        var _this = this;
        if (Array.isArray(action)) {
            action.forEach(function (one) { return _this.when(one, scope); });
        }
        else {
            this.actionScopes[action] = this.actionScopes[action] || [];
            this.actionScopes[action].push(scope);
        }
        return this;
    };
    Resource.prototype.where = function (param, paramValue) {
        var _this = this;
        if (isObject(param)) {
            Object.keys(param)
                .forEach(function (name) { return _this.where(name, param[name]); });
        }
        else {
            this.params[param] = paramValue;
        }
        return this;
    };
    Resource.prototype.whereId = function (id) {
        return this.where(this.idName, id);
    };
    Resource.prototype.whereSub = function (path) {
        return this.where('subPath', path);
    };
    Resource.prototype.skip = function (n) {
        return this.where(this.queryNames.skip, n);
    };
    Resource.prototype.take = function (n) {
        return this.where(this.queryNames.take, n);
    };
    Resource.prototype.page = function (n, size) {
        return this.where(this.queryNames.page, n)
            .where(this.queryNames.pageSize, size);
    };
    Resource.prototype.order = function (by, desc) {
        if (desc === void 0) { desc = false; }
        return this.where(this.queryNames.order, "" + (desc ? '-' : '') + by);
    };
    Resource.prototype.get = function () {
        var _this = this;
        this.applyActionScopes(ResourceAction.Get);
        var _a = this.actionParams, path = _a.path, query = _a.query;
        return this.client
            .get(this.buildPath(path), {
            headers: this.headers,
            params: query,
            responseType: this.responseType
        })
            .pipe(map(function (response) {
            if (_this.isPaginated(response)) {
                return _this.paginate(response);
            }
            // const data = response.data ? response.data : response;
            // return (Array.isArray(data) ? data : [ data ]).map((item) => this.fabricator(item));
            return (Array.isArray(response) ? response : [response]).map(function (item) { return _this.fabricator(item); });
        }));
    };
    Resource.prototype.first = function () {
        return this.get()
            .pipe(map(function (response) { return Array.isArray(response) ? response.shift()
            : response.data.shift(); }));
    };
    Resource.prototype.find = function (id) {
        return this.whereId(id).first();
    };
    Resource.prototype.create = function (update) {
        var _this = this;
        this.applyActionScopes(ResourceAction.Create);
        var _a = this.actionParams, path = _a.path, query = _a.query;
        return this.client
            .post(this.buildPath(path), update, {
            headers: this.headers,
            params: query,
            responseType: this.responseType
        })
            .pipe(map(function (response) { return _this.fabricator(response); }));
    };
    Resource.prototype.update = function (update, id) {
        var _this = this;
        this.applyActionScopes(ResourceAction.Update);
        if (id) {
            this.whereId(id);
        }
        var _a = this.actionParams, path = _a.path, query = _a.query;
        return this.client
            .put(this.buildPath(path), update, {
            headers: this.headers,
            params: query,
            responseType: this.responseType
        })
            .pipe(map(function (response) {
            if (response) {
                return _this.fabricator(response);
            }
        }));
    };
    Resource.prototype.delete = function (id) {
        this.applyActionScopes(ResourceAction.Delete);
        if (id) {
            this.whereId(id);
        }
        var _a = this.actionParams, path = _a.path, query = _a.query;
        return this.client
            .delete(this.buildPath(path), {
            headers: this.headers,
            params: query,
            responseType: this.responseType
        });
    };
    return Resource;
}());
export { Resource };
