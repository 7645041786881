import * as tslib_1 from "tslib";
import { BaseModel } from '../../common/model/base.model';
import { Property } from '../../common/model/property.decorator';
export var UserRole;
(function (UserRole) {
    UserRole["SuperUser"] = "super-user";
    UserRole["MasterUser"] = "master-user";
    UserRole["User"] = "user";
})(UserRole || (UserRole = {}));
export var HomeRoutes = new Map([
    [UserRole.SuperUser, '/admin'],
    [UserRole.MasterUser, '/users'],
    [UserRole.User, '/reports']
]);
var UserModel = /** @class */ (function (_super) {
    tslib_1.__extends(UserModel, _super);
    function UserModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(UserModel.prototype, "fullName", {
        get: function () {
            return this.firstName + " " + this.lastName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserModel.prototype, "homeRoute", {
        get: function () {
            return HomeRoutes.get(this.role);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserModel.prototype, "isAdmin", {
        get: function () {
            return this.hasRole(UserRole.SuperUser);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserModel.prototype, "isMaster", {
        get: function () {
            return this.hasRole(UserRole.MasterUser);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserModel.prototype, "isStaff", {
        get: function () {
            return this.hasRole(UserRole.User);
        },
        enumerable: true,
        configurable: true
    });
    UserModel.prototype.hasRole = function (role) {
        return Array.isArray(role) ? role.includes(this.role) : role === this.role;
    };
    tslib_1.__decorate([
        Property()
    ], UserModel.prototype, "id", void 0);
    tslib_1.__decorate([
        Property({
            sourceName: 'organization_id'
        })
    ], UserModel.prototype, "organizationId", void 0);
    tslib_1.__decorate([
        Property()
    ], UserModel.prototype, "role", void 0);
    tslib_1.__decorate([
        Property()
    ], UserModel.prototype, "email", void 0);
    tslib_1.__decorate([
        Property({
            sourceName: 'first_name'
        })
    ], UserModel.prototype, "firstName", void 0);
    tslib_1.__decorate([
        Property({
            sourceName: 'last_name'
        })
    ], UserModel.prototype, "lastName", void 0);
    return UserModel;
}(BaseModel));
export { UserModel };
