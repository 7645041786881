import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { finalize, tap } from 'rxjs/operators';
import { ApiFormComponent } from '../../forms/api-form.component';
import { voider } from '../../../common/utils';
var CompanyModifyComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyModifyComponent, _super);
    function CompanyModifyComponent(dialogRef, apiResourcesService, apiService, data) {
        var _this = _super.call(this, apiService) || this;
        _this.dialogRef = dialogRef;
        _this.apiResourcesService = apiResourcesService;
        _this.fieldProgressBarModes = {};
        _this.currencies = [];
        _this.parents = [];
        _this.managers = [];
        _this.company = data && data.company;
        _this.parent = data && data.parent;
        _this.title = data && data.title || (_this.isNew ? 'Create company' : 'Edit company');
        return _this;
    }
    Object.defineProperty(CompanyModifyComponent.prototype, "companyId", {
        /*visible = true;
        selectable = true;
        removable = true;
        addOnBlur = true;
        separatorKeysCodes: number[] = [ENTER, COMMA];
        activeManagersList: Array<UserModel> = [];
        @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;*/
        get: function () {
            return this.company && this.company.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyModifyComponent.prototype, "parentId", {
        get: function () {
            return this.parent && this.parent.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyModifyComponent.prototype, "isNew", {
        get: function () {
            return !this.companyId;
        },
        enumerable: true,
        configurable: true
    });
    CompanyModifyComponent.prototype.withFieldProgressBar = function (field, subject) {
        var _this = this;
        this.fieldProgressBarModes[field] = 'query';
        return subject.pipe(finalize(function () { return _this.fieldProgressBarModes[field] = null; }));
    };
    CompanyModifyComponent.prototype.createForm = function () {
        var formGroup = new FormGroup({
            name: new FormControl(null, [
                Validators.required,
                Validators.maxLength(50)
            ]),
            currency_id: new FormControl(null, [
                Validators.required
            ])
        });
        if (this.isNew) {
            formGroup.addControl('parent_id', new FormControl(this.parentId, [Validators.required]));
        }
        if (this.isNew || !this.company.isMasterCompany) {
            formGroup.addControl('managers_ids', new FormControl(null, [Validators.required]));
        }
        return formGroup;
    };
    CompanyModifyComponent.prototype.updateFormValue = function () {
        var e_1, _a;
        if (!this.isNew) {
            /*if (!this.managers.find((manager) => manager.equal(this.company.managers[0]))) {
                //this.managers.push(this.company.managers);
            }*/
            this.form.controls.name.setValue(this.company.name);
            this.form.controls.currency_id.setValue(this.company.currency.id);
            if (this.form.controls.managers_ids) {
                var currentCompanyManagers = [];
                try {
                    for (var _b = tslib_1.__values(this.company.managers), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var manager = _c.value;
                        currentCompanyManagers.push(manager.id);
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                this.form.controls.managers_ids.setValue(currentCompanyManagers);
            }
        }
    };
    CompanyModifyComponent.prototype.reloadCurrencies = function () {
        var _this = this;
        return this.withFieldProgressBar('currency_id', this.apiResourcesService.selectedCurrency
            .get()
            .pipe(tap(function (currencies) { return _this.currencies = currencies; })))
            .toPromise()
            .then(voider);
    };
    CompanyModifyComponent.prototype.reloadParents = function () {
        var _this = this;
        return this.withFieldProgressBar('parent_id', this.apiResourcesService.company
            .get()
            .pipe(tap(function (companies) {
            _this.parents = companies;
        })))
            .toPromise()
            .then(voider);
    };
    CompanyModifyComponent.prototype.reloadManagers = function () {
        var _this = this;
        return this.withFieldProgressBar('managers_ids', this.apiResourcesService.staff
            .get()
            .pipe(tap(function (managers) {
            _this.managers = managers.data;
        })))
            .toPromise()
            .then(voider);
    };
    CompanyModifyComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form = this.createForm();
        this.subscribeOnErrors(this.form);
        var promises = [];
        promises.push(this.reloadCurrencies());
        if (this.form.contains('parent_id')) {
            promises.push(this.reloadParents());
        }
        if (this.form.contains('managers_ids')) {
            promises.push(this.reloadManagers());
        }
        Promise.all(promises).then(function () { return _this.updateFormValue(); });
    };
    /*displayFn(manager): string | undefined {
      return manager ? manager.fullName : undefined;
    }

    onSearchChange(searchValue: string): void {
      this.apiResourcesService.staff
        .where('manager', 0)
        .where('query', searchValue)
        .get()
        .subscribe((managers: PaginatedItems<any>) => {
          this.managers = managers.data;
        });
    }

    selectedManager(manager: any): void {
        this.managerId = manager.id;
    }*/
    CompanyModifyComponent.prototype.cancel = function () {
        this.dialogRef.close();
    };
    CompanyModifyComponent.prototype.save = function () {
        var _this = this;
        var e_2, _a;
        var formValue = {
            name: this.form.value.name,
            currency_id: this.form.value.currency_id,
            parent_id: this.form.value.parent_id,
            managers_ids: this.form.value.managers_ids
        };
        if (!this.form.contains('managers_ids')) {
            formValue.managers_ids = [];
            try {
                for (var _b = tslib_1.__values(this.company.managers), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var manager = _c.value;
                    formValue.managers_ids.push(manager.id);
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
        this.withProgressBar(this.apiResourcesService.company[this.isNew ? 'create' : 'update'](formValue, this.companyId))
            .subscribe(function (company) { return _this.dialogRef.close(company); });
    };
    return CompanyModifyComponent;
}(ApiFormComponent));
export { CompanyModifyComponent };
