import * as tslib_1 from "tslib";
import { Resource } from './resource';
import { ModelFactory } from '../model/model.factory';
var ModelResource = /** @class */ (function (_super) {
    tslib_1.__extends(ModelResource, _super);
    function ModelResource(client, name, model, parents) {
        if (parents === void 0) { parents = []; }
        var _this = _super.call(this, client, name, parents) || this;
        _this.model = model;
        _this.factory = new ModelFactory(_this.model);
        return _this;
    }
    Object.defineProperty(ModelResource.prototype, "fabricator", {
        get: function () {
            var _this = this;
            return function (attributes) { return _this.factory.create(attributes); };
        },
        enumerable: true,
        configurable: true
    });
    return ModelResource;
}(Resource));
export { ModelResource };
