import * as tslib_1 from "tslib";
import { BaseFactory } from '../base.factory';
var ModelFactory = /** @class */ (function (_super) {
    tslib_1.__extends(ModelFactory, _super);
    function ModelFactory(model) {
        var _this = _super.call(this, function (attributes) { return new _this.model(attributes); }) || this;
        _this.model = model;
        return _this;
    }
    return ModelFactory;
}(BaseFactory));
export { ModelFactory };
