import * as tslib_1 from "tslib";
import { ModelResource } from '../../common/http/model-resource';
import { value } from '../../common/utils';
var ApiResource = /** @class */ (function (_super) {
    tslib_1.__extends(ApiResource, _super);
    function ApiResource() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ApiResource.prototype.authorize = function () {
        return this.useAuthBearer(this.client.authTokenGetter);
    };
    ApiResource.prototype.authorizeUrl = function () {
        return this.where('api_token', value(this.client.authTokenGetter));
    };
    return ApiResource;
}(ModelResource));
export { ApiResource };
