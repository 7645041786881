import * as tslib_1 from "tslib";
import { BaseModel } from '../../common/model/base.model';
import { Property } from '../../common/model/property.decorator';
var AccountModel = /** @class */ (function (_super) {
    tslib_1.__extends(AccountModel, _super);
    function AccountModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    tslib_1.__decorate([
        Property()
    ], AccountModel.prototype, "id", void 0);
    tslib_1.__decorate([
        Property()
    ], AccountModel.prototype, "code", void 0);
    tslib_1.__decorate([
        Property()
    ], AccountModel.prototype, "name", void 0);
    tslib_1.__decorate([
        Property()
    ], AccountModel.prototype, "type", void 0);
    tslib_1.__decorate([
        Property()
    ], AccountModel.prototype, "local_mapping", void 0);
    tslib_1.__decorate([
        Property()
    ], AccountModel.prototype, "group_mapping", void 0);
    return AccountModel;
}(BaseModel));
export { AccountModel };
