import * as tslib_1 from "tslib";
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { Validators } from '../../../common/forms/validators';
import { ApiFormComponent } from '../../forms/api-form.component';
import { Validations } from '../../forms/validations';
var UserModifyComponent = /** @class */ (function (_super) {
    tslib_1.__extends(UserModifyComponent, _super);
    function UserModifyComponent(dialogRef, apiResourcesService, userService, apiService, data) {
        var _this = _super.call(this, apiService) || this;
        _this.dialogRef = dialogRef;
        _this.apiResourcesService = apiResourcesService;
        _this.userService = userService;
        _this.isMaster = data && data.isMaster;
        _this.user = data && data.user;
        _this.title = data && data.title || (_this.isNew ? 'Create user' : 'Edit user');
        return _this;
    }
    Object.defineProperty(UserModifyComponent.prototype, "userId", {
        get: function () {
            return this.user && this.user.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserModifyComponent.prototype, "isNew", {
        get: function () {
            return !this.userId;
        },
        enumerable: true,
        configurable: true
    });
    UserModifyComponent.prototype.createForm = function () {
        var formGroup = new FormGroup(tslib_1.__assign({ email: new FormControl(null, tslib_1.__spread([
                Validators.required
            ], Validations.getEmailRules())), first_name: new FormControl(null, [
                Validators.required,
                Validators.maxLength(50)
            ]), last_name: new FormControl(null, [
                Validators.required,
                Validators.maxLength(50)
            ]) }, Validators.withConfirmation(new FormControl(null, tslib_1.__spread(Validations.getPasswordRules())), 'password', this.subscriptions)));
        if (!this.isNew) {
            formGroup.addControl.apply(formGroup, tslib_1.__spread(Validators.passworded(formGroup.get('password'), 'password', this.subscriptions, Validations.getPasswordRules())));
        }
        if (this.isNew && this.isMaster) {
            formGroup.addControl('master_checkbox', new FormControl(false, []));
        }
        return formGroup;
    };
    UserModifyComponent.prototype.updateFormValue = function () {
        if (!this.isNew) {
            this.form.patchValue(this.user.toObject());
        }
    };
    UserModifyComponent.prototype.cancel = function () {
        this.dialogRef.close();
    };
    UserModifyComponent.prototype.save = function () {
        var _this = this;
        if (this.form.get('master_checkbox') && this.form.get('master_checkbox').value) {
            this.withProgressBar(this.userService.createMasterUser(this.form.value))
                .subscribe(function (user) {
                _this.dialogRef.close(user);
            });
        }
        else {
            this.withProgressBar(this.apiResourcesService.staff[this.isNew ? 'create' : 'update'](this.form.value, this.userId))
                .subscribe(function (user) {
                _this.dialogRef.close(user);
            });
        }
    };
    return UserModifyComponent;
}(ApiFormComponent));
export { UserModifyComponent };
