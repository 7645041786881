import * as tslib_1 from "tslib";
import { BaseModel } from '../../common/model/base.model';
import { Property } from '../../common/model/property.decorator';
var AuthTokenModel = /** @class */ (function (_super) {
    tslib_1.__extends(AuthTokenModel, _super);
    function AuthTokenModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(AuthTokenModel, "idName", {
        get: function () {
            return 'token';
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        Property()
    ], AuthTokenModel.prototype, "token", void 0);
    return AuthTokenModel;
}(BaseModel));
export { AuthTokenModel };
