import * as tslib_1 from "tslib";
var BaseFactory = /** @class */ (function () {
    function BaseFactory(fabricator) {
        this.fabricator = fabricator;
    }
    BaseFactory.prototype.create = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return this.fabricator.apply(this, tslib_1.__spread(args));
    };
    return BaseFactory;
}());
export { BaseFactory };
