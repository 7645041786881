import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
var CurrencyComponent = /** @class */ (function () {
    function CurrencyComponent(fb, apiResourcesService) {
        this.fb = fb;
        this.apiResourcesService = apiResourcesService;
    }
    Object.defineProperty(CurrencyComponent.prototype, "currencyArrayControl", {
        get: function () {
            return this.form.get('currencyArray');
        },
        enumerable: true,
        configurable: true
    });
    CurrencyComponent.prototype.ngOnInit = function () {
        this.createForm();
        this.getCurrencyList();
    };
    CurrencyComponent.prototype.createForm = function () {
        this.form = this.fb.group({
            currencyArray: this.fb.array([])
        });
    };
    CurrencyComponent.prototype.createItem = function (id, name, shortName, active) {
        return this.fb.group({
            id: this.fb.control(id, []),
            active: this.fb.control(active, [])
        });
    };
    CurrencyComponent.prototype.getCurrencyList = function () {
        var _this = this;
        this.progressBarMode = 'query';
        this.apiResourcesService.currency
            .get()
            .pipe(finalize(function () {
            _this.progressBarMode = null;
        }))
            .subscribe(function (currency) {
            var e_1, _a;
            _this.currencyList = currency;
            _this.currencyArray = _this.form.get("currencyArray");
            try {
                for (var _b = tslib_1.__values(_this.currencyList), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var currency_1 = _c.value;
                    _this.currencyArray.push(_this.createItem(currency_1.id, currency_1.name, currency_1.shortName, currency_1.selected));
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        });
    };
    CurrencyComponent.prototype.save = function () {
        var _this = this;
        this.progressBarMode = 'query';
        var selectedCurrency = [];
        for (var i = 0; i < this.currencyArrayControl.length; i++) {
            if (this.currencyArrayControl.value[i].active === true) {
                selectedCurrency.push(this.currencyArrayControl.value[i].id);
            }
        }
        this.apiResourcesService.setActiveCurrency
            .create({
            currencies_ids: selectedCurrency
        })
            .pipe(finalize(function () {
            _this.progressBarMode = null;
        })).subscribe();
    };
    return CurrencyComponent;
}());
export { CurrencyComponent };
