import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { of, throwError } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { EventEmitter } from '@angular/core';
import { ExternalErrorsProxy } from '../../common/forms/external-errors-proxy';
import { trimSlashes } from '../../common/http/utils';
var ApiService = /** @class */ (function () {
    function ApiService(httpClient, notificationsService) {
        this.httpClient = httpClient;
        this.notificationsService = notificationsService;
        this.httpErrors = new EventEmitter();
        this.authTokenGetter = function () { return ''; };
    }
    Object.defineProperty(ApiService.prototype, "errorHandler", {
        get: function () {
            var _this = this;
            return function (error) { return _this.handleError(error); };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApiService.prototype, "errorSuppressor", {
        get: function () {
            var _this = this;
            return function (error) { return _this.suppressError(error); };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApiService.prototype, "endpoint", {
        get: function () {
            return environment.apiEndpoint;
        },
        enumerable: true,
        configurable: true
    });
    ApiService.prototype.handleHttpErrorResponse = function (response) {
        this.httpErrors.emit(response);
        var errors = [];
        if (response.error.errors) {
            var errArray = Object.values(response.error.errors);
            errArray.forEach(function (msg) {
                errors.push(msg);
            });
        }
        else if (response.error.messages) {
            errors.push(response.error.messages);
        }
        this.notificationsService.show(errors.join("\r\n") || response.error.message);
    };
    ApiService.prototype.handleError = function (error) {
        if (error instanceof HttpErrorResponse) {
            this.handleHttpErrorResponse(error);
        }
        else {
            if (!error.message) {
                error.message = 'Server error';
            }
            this.notificationsService.show(error.message);
        }
        return throwError(error);
    };
    ApiService.prototype.suppressError = function (error) {
        console.error(error);
        return of(false);
    };
    ApiService.prototype.withQuery = function (url, params) {
        var query = (new HttpParams({ fromObject: params })).toString();
        if (query.length > 0) {
            var qIdx = url.indexOf('?');
            var sep = qIdx === -1 ? '?' : (qIdx < url.length - 1 ? '&' : '');
            url += sep + query;
        }
        return url;
    };
    ApiService.prototype.url = function (path, params) {
        var url = this.endpoint + "/" + trimSlashes(path);
        return params ? this.withQuery(url, params) : url;
    };
    ApiService.prototype.get = function (path, options) {
        return this.httpClient
            .get(this.url(path), options)
            .pipe(catchError(this.errorHandler));
    };
    ApiService.prototype.post = function (path, body, options) {
        return this.httpClient
            .post(this.url(path), body, options)
            .pipe(catchError(this.errorHandler));
    };
    ApiService.prototype.put = function (path, body, options) {
        return this.httpClient
            .put(this.url(path), body, options)
            .pipe(catchError(this.errorHandler));
    };
    ApiService.prototype.delete = function (path, options) {
        return this.httpClient
            .delete(this.url(path), options)
            .pipe(catchError(this.errorHandler));
    };
    ApiService.prototype.on = function (status) {
        return this.httpErrors
            .pipe(filter(function (response) { return response.status === status; }), map(function (response) { return response.error; }));
    };
    ApiService.prototype.proxyValidation = function (target) {
        return new ExternalErrorsProxy(this.on(422)
            .pipe(map(function (_a) {
            var errors = _a.errors;
            return Object.keys(errors)
                .reduce(function (controlsErrors, field) {
                controlsErrors[field] = errors[field].reduce(function (fieldErrors, message, i) {
                    fieldErrors[i] = { message: message };
                    return fieldErrors;
                }, {});
                return controlsErrors;
            }, {});
        })), target, 'app:api:');
    };
    return ApiService;
}());
export { ApiService };
