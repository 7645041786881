import * as tslib_1 from "tslib";
import { AbstractControl, FormControl, Validators as NgValidators } from '@angular/forms';
var Validators = /** @class */ (function (_super) {
    tslib_1.__extends(Validators, _super);
    function Validators() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Validators.isEmptyControlValue = function (control) {
        return control.value == null || control.value.length === 0;
    };
    Validators.requiredIf = function (conditionFn) {
        var _this = this;
        return function (control) { return conditionFn() ? _this.required(control) : null; };
    };
    Validators.equalTo = function (target, targetName) {
        var _this = this;
        return function (control) {
            if (_this.isEmptyControlValue(control)) {
                return null;
            }
            if (typeof target === 'string' && !targetName) {
                targetName = target;
            }
            target = target instanceof AbstractControl ? target : control.parent.get(target);
            return control.value === target.value ? null : { equalTo: { target: targetName } };
        };
    };
    Validators.confirmation = function (target, targetName, subscription, suffix) {
        if (suffix === void 0) { suffix = '_confirmation'; }
        var validators = [
            this.requiredIf(function () { return !!target.value; }),
            this.equalTo(target, targetName)
        ];
        var control = new target.constructor(null, validators);
        subscription.add(target.valueChanges.subscribe(function () { return control.updateValueAndValidity(); }));
        return ["" + targetName + suffix, control];
    };
    Validators.withConfirmation = function (target, targetName, subscription, suffix) {
        var _a;
        var _b = tslib_1.__read(this.confirmation(target, targetName, subscription, suffix), 2), name = _b[0], control = _b[1];
        return _a = {},
            _a[targetName] = target,
            _a[name] = control,
            _a;
    };
    Validators.passworded = function (target, targetName, subscription, validators, suffix) {
        if (validators === void 0) { validators = []; }
        if (suffix === void 0) { suffix = '_passworded'; }
        validators.unshift(this.requiredIf(function () { return !!target.value; }));
        var control = new FormControl(null, validators);
        subscription.add(target.valueChanges.subscribe(function () { return control.updateValueAndValidity(); }));
        return ["" + targetName + suffix, control];
    };
    Validators.withPassworded = function (target, targetName, subscription, validators, suffix) {
        var _a;
        if (validators === void 0) { validators = []; }
        var _b = tslib_1.__read(this.passworded(target, targetName, subscription, validators, suffix), 2), name = _b[0], control = _b[1];
        return _a = {},
            _a[targetName] = target,
            _a[name] = control,
            _a;
    };
    return Validators;
}(NgValidators));
export { Validators };
