import { BehaviorSubject } from 'rxjs';
var LocalStorage = /** @class */ (function () {
    function LocalStorage(key, fabricator) {
        if (fabricator === void 0) { fabricator = function (stored) { return stored || undefined; }; }
        this.key = key;
        this.fabricator = fabricator;
        this.get();
    }
    Object.defineProperty(LocalStorage.prototype, "value", {
        get: function () {
            return this.get();
        },
        enumerable: true,
        configurable: true
    });
    LocalStorage.prototype.get = function () {
        if (this.subject === undefined) {
            var value = localStorage.getItem(this.key);
            if (value) {
                try {
                    this.subject = new BehaviorSubject(this.fabricator(JSON.parse(value)));
                }
                catch (e) {
                    console.warn("Storage '" + this.key + "' contains broken data");
                }
            }
            else {
                this.subject = new BehaviorSubject(this.fabricator());
            }
        }
        return this.subject.value;
    };
    LocalStorage.prototype.set = function (value) {
        localStorage.setItem(this.key, JSON.stringify(value));
        this.subject.next(value);
    };
    LocalStorage.prototype.has = function () {
        return this.subject.value === this.fabricator();
    };
    LocalStorage.prototype.unset = function () {
        localStorage.removeItem(this.key);
        this.subject.next(this.fabricator());
    };
    return LocalStorage;
}());
export { LocalStorage };
