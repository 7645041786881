import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ReportType, TotalModel, TotalReportModel } from '../../models/report.model';
import { MatPaginator } from '@angular/material';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';
import { FormControl } from "@angular/forms";
import { MY_FORMATS } from "../reports.component";
var ɵ0 = MY_FORMATS;
var GeneralReportComponent = /** @class */ (function () {
    function GeneralReportComponent(dialog, apiResourcesService, router, location) {
        this.dialog = dialog;
        this.apiResourcesService = apiResourcesService;
        this.router = router;
        this.location = location;
        this.displayedColumns = ['code', 'name', 'type'];
        this.displayedDynamicColumns = [];
        this.accounts = [];
        this.totalCtr = 0;
        this.perPage = 100;
        this.pageIndex = 0;
        this.paginationVisibility = true;
        this.subscriptions = new Subscription();
        this.date = new FormControl({ value: moment(), disabled: true });
        this.formDate = new FormControl({ value: '', disabled: true });
        this.tomorrow = new Date();
        this.tomorrow.setDate(this.tomorrow.getDate());
    }
    GeneralReportComponent.prototype.ngOnInit = function () {
        this.getSummaryReportData(moment().subtract(1, 'month').format('YYYY-MM'));
        this.formDate.setValue(moment().subtract(1, 'month').format('YYYY-MM'));
    };
    GeneralReportComponent.prototype.getSummaryReportData = function (data) {
        var _this = this;
        this.progressBarMode = 'query';
        this.apiResourcesService.reportSummaryRecord
            .whereId(data)
            .where('type', ReportType.Json)
            .first()
            .pipe(finalize(function () {
            _this.progressBarMode = null;
        }))
            .subscribe(function (totalReport) {
            var e_1, _a, e_2, _b, e_3, _c, e_4, _d, e_5, _e;
            if (totalReport.reports) {
                _this.reportsArray = totalReport.reports;
                try {
                    for (var _f = tslib_1.__values(_this.reportsArray), _g = _f.next(); !_g.done; _g = _f.next()) {
                        var report = _g.value;
                        var _loop_1 = function (acc) {
                            var index = _this.accounts.findIndex(function (x) { return x.id === acc.id; });
                            if (index === -1) {
                                _this.accounts.push(acc);
                            }
                        };
                        try {
                            for (var _h = tslib_1.__values(report.accounts), _j = _h.next(); !_j.done; _j = _h.next()) {
                                var acc = _j.value;
                                _loop_1(acc);
                            }
                        }
                        catch (e_2_1) { e_2 = { error: e_2_1 }; }
                        finally {
                            try {
                                if (_j && !_j.done && (_b = _h.return)) _b.call(_h);
                            }
                            finally { if (e_2) throw e_2.error; }
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_g && !_g.done && (_a = _f.return)) _a.call(_f);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                _this.total = totalReport.total;
                _this.targetCurrency = _this.reportsArray[0].targetCurrency;
                try {
                    for (var _k = tslib_1.__values(_this.reportsArray), _l = _k.next(); !_l.done; _l = _k.next()) {
                        var report = _l.value;
                        _this.displayedDynamicColumns.push(report.company.name);
                        _this.totalCtr += report.ctr;
                    }
                }
                catch (e_3_1) { e_3 = { error: e_3_1 }; }
                finally {
                    try {
                        if (_l && !_l.done && (_c = _k.return)) _c.call(_k);
                    }
                    finally { if (e_3) throw e_3.error; }
                }
                _this.accounts.sort(function (a, b) {
                    return (a.id - b.id);
                });
                try {
                    for (var _m = tslib_1.__values(_this.reportsArray), _o = _m.next(); !_o.done; _o = _m.next()) {
                        var report = _o.value;
                        var _loop_2 = function (acc) {
                            var index = report.accounts.findIndex(function (x) { return x.id === acc.id; });
                            if (index === -1) {
                                var faceAcc = {
                                    id: acc.id,
                                    balance: 0
                                };
                                report.accounts.push(faceAcc);
                            }
                        };
                        try {
                            for (var _p = tslib_1.__values(_this.accounts), _q = _p.next(); !_q.done; _q = _p.next()) {
                                var acc = _q.value;
                                _loop_2(acc);
                            }
                        }
                        catch (e_5_1) { e_5 = { error: e_5_1 }; }
                        finally {
                            try {
                                if (_q && !_q.done && (_e = _p.return)) _e.call(_p);
                            }
                            finally { if (e_5) throw e_5.error; }
                        }
                        report.accounts.sort(function (a, b) {
                            return (a.id - b.id);
                        });
                    }
                }
                catch (e_4_1) { e_4 = { error: e_4_1 }; }
                finally {
                    try {
                        if (_o && !_o.done && (_d = _m.return)) _d.call(_m);
                    }
                    finally { if (e_4) throw e_4.error; }
                }
            }
        });
    };
    GeneralReportComponent.prototype.download = function () {
        var link = this.apiResourcesService.reportSummaryRecord
            //.whereId(moment().subtract(1, 'month').format('YYYY-MM'))
            .whereId(this.formDate.value)
            .where('type', ReportType.Xlsx)
            .authorizeUrl().url;
        window.open(link);
    };
    GeneralReportComponent.prototype.goBack = function () {
        this.location.back();
    };
    GeneralReportComponent.prototype.chosenYearHandler = function (normalizedYear) {
        var ctrlValue = this.date.value;
        ctrlValue.year(normalizedYear.year());
        this.formDate.setValue(ctrlValue.format('YYYY-MM'));
    };
    GeneralReportComponent.prototype.chosenMonthHandler = function (normalizedMonth, datepicker) {
        var ctrlValue = this.date.value;
        ctrlValue.month(normalizedMonth.month());
        this.formDate.setValue(ctrlValue.format('YYYY-MM'));
        this.reportsArray = [];
        this.displayedDynamicColumns = [];
        this.total = [];
        this.accounts = [];
        this.getSummaryReportData(ctrlValue.format('YYYY-MM'));
        datepicker.close();
    };
    GeneralReportComponent.prototype.closePicker = function () {
        this.reportsArray = [];
        this.displayedDynamicColumns = [];
        this.total = [];
        this.accounts = [];
        this.getSummaryReportData(this.formDate.value);
    };
    return GeneralReportComponent;
}());
export { GeneralReportComponent };
export { ɵ0 };
