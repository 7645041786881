import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { ReportType } from '../../models/report.model';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { UploadReportModalComponent } from '../upload-report-modal/upload-report-modal.component';
var CreateReportComponent = /** @class */ (function () {
    function CreateReportComponent(location, apiResourcesService, fb, activatedRoute, snackBar, dialog, authService) {
        this.location = location;
        this.apiResourcesService = apiResourcesService;
        this.fb = fb;
        this.activatedRoute = activatedRoute;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.authService = authService;
        this.subscriptions = new Subscription();
        this.displayedColumns = ['id', 'code', 'name', 'type', 'balance'];
        this.reportDiff = [];
        this.reportSum = [];
        this.notCompanyManager = false;
        /*TODO: Check init form*/
        this.form = this.fb.group({
            balancesArray: this.fb.array([])
        });
    }
    Object.defineProperty(CreateReportComponent.prototype, "balancesArrayControl", {
        get: function () {
            return this.form.get('balancesArray');
        },
        enumerable: true,
        configurable: true
    });
    CreateReportComponent.prototype.createForm = function () {
        this.form = this.fb.group({
            balancesArray: this.fb.array([this.createItem()])
        });
    };
    CreateReportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.add(this.authService.user.subscribe(function (user) { return _this.user = user; }));
        this.activatedRoute.params.subscribe(function (params) {
            _this.companyId = params['companyId'];
        });
        this.getReport();
    };
    CreateReportComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    CreateReportComponent.prototype.createItem = function () {
        return this.fb.group({
            balance: new FormControl()
        });
    };
    CreateReportComponent.prototype.getReport = function () {
        var _this = this;
        this.progressBarMode = 'query';
        this.apiResourcesService.reportShow
            .whereId(moment().subtract(1, 'month').format('YYYY-MM') + '/' + this.companyId)
            .where('type', ReportType.Json)
            .first()
            .pipe(finalize(function () { return (_this.progressBarMode = null); }))
            .subscribe(function (report) {
            var e_1, _a;
            _this.report = report;
            _this.reportAccounts = report.accounts;
            try {
                for (var _b = tslib_1.__values(_this.reportAccounts), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var value = _c.value;
                    _this.reportSum.push({
                        account_id: value.id,
                        balance: Number(value.balance)
                    });
                    _this.reportDiff.push({
                        account_id: value.id,
                        balance: Number(value.balance)
                    });
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            _this.getTotal();
            _this.createForm();
        });
    };
    CreateReportComponent.prototype.onBalanceChange = function (target, id) {
        var oldValueIndex;
        var oldReportValueIndex;
        var newBalance = target.value;
        if (isNaN(newBalance)) {
            newBalance = 0;
        }
        if (this.reportDiff) {
            oldValueIndex = this.reportDiff.findIndex(function (el) { return el.account_id === id; });
        }
        if (oldValueIndex > 0 || oldValueIndex === 0) {
            this.reportDiff[oldValueIndex] = {
                account_id: id,
                balance: Number(newBalance)
            };
        }
        else {
            this.reportDiff.push({
                account_id: id,
                balance: Number(newBalance)
            });
        }
        if (this.reportSum) {
            oldReportValueIndex = this.reportSum.findIndex(function (el) { return el.account_id === id; });
        }
        if (oldReportValueIndex > 0 || oldReportValueIndex === 0) {
            this.reportSum[oldReportValueIndex] = {
                account_id: id,
                balance: Number(newBalance)
            };
        }
        else {
            this.reportSum.push({
                account_id: id,
                balance: Number(newBalance)
            });
        }
        this.getTotal();
    };
    CreateReportComponent.prototype.openSnackBar = function (message, action) {
        this.snackBar.open(message, action, {
            duration: 3000,
            panelClass: ['warning_snackbar']
        });
    };
    CreateReportComponent.prototype.save = function () {
        var _this = this;
        if (!this.validTotal) {
            this.openSnackBar('Total balance must be zero!');
        }
        else {
            this.apiResourcesService.reportBalance
                .where('type', ReportType.Json)
                .update({
                accounts: this.reportDiff
            }, moment().subtract(1, 'month').format('YYYY-MM') + '/' + this.companyId)
                .subscribe(function () {
                _this.getReport();
                _this.reportDiff = [];
            });
        }
    };
    CreateReportComponent.prototype.getTotal = function () {
        var e_2, _a;
        var total = 0;
        try {
            for (var _b = tslib_1.__values(this.reportSum), _c = _b.next(); !_c.done; _c = _b.next()) {
                var value = _c.value;
                total += value.balance;
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        total === 0 ? this.validTotal = true : this.validTotal = false;
        if (isNaN(total)) {
            return '';
        }
        else {
            var fixTotal = total.toFixed(9);
            return parseFloat(fixTotal.toString());
        }
    };
    CreateReportComponent.prototype.onFocusOut = function (event) {
        if (!event.target.value || !Number(+event.target.value)) {
            event.target.value = 0;
        }
    };
    CreateReportComponent.prototype.goBack = function () {
        this.location.back();
    };
    CreateReportComponent.prototype.download = function () {
        var link = this.apiResourcesService.reportShow
            .whereId(moment().subtract(1, 'month').format('YYYY-MM') + '/' + this.companyId)
            .where('type', ReportType.Xlsx)
            .authorizeUrl().url;
        window.open(link);
    };
    CreateReportComponent.prototype.upload = function () {
        var dialogRef = this.dialog.open(UploadReportModalComponent, {
            width: '400px',
            data: {
                companyId: this.companyId
            }
        });
        dialogRef.afterClosed().subscribe();
    };
    return CreateReportComponent;
}());
export { CreateReportComponent };
