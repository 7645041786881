import * as tslib_1 from "tslib";
import { BaseModel } from '../../common/model/base.model';
import { Property } from '../../common/model/property.decorator';
import { UserModel } from './user.model';
import { AuthTokenModel } from './auth-token.model';
var AccessModel = /** @class */ (function (_super) {
    tslib_1.__extends(AccessModel, _super);
    function AccessModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(AccessModel, "idName", {
        get: function () {
            return 'token';
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        Property({
            typeOrFactory: UserModel
        })
    ], AccessModel.prototype, "user", void 0);
    tslib_1.__decorate([
        Property({
            typeOrFactory: AuthTokenModel
        })
    ], AccessModel.prototype, "token", void 0);
    return AccessModel;
}(BaseModel));
export { AccessModel };
