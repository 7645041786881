import * as tslib_1 from "tslib";
import { FormControl, FormGroup } from '@angular/forms';
import { Validators } from '../../../../common/forms/validators';
import { Validations } from '../../../forms/validations';
import { MatDialogRef } from '@angular/material';
import { ApiFormComponent } from '../../../forms/api-form.component';
var AdminUserModifyComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AdminUserModifyComponent, _super);
    function AdminUserModifyComponent(dialogRef, apiResourcesService, apiService, adminService, data) {
        var _this = _super.call(this, apiService) || this;
        _this.dialogRef = dialogRef;
        _this.apiResourcesService = apiResourcesService;
        _this.adminService = adminService;
        _this.title = 'Invite master user';
        return _this;
    }
    AdminUserModifyComponent.prototype.createForm = function () {
        var adminFormGroup = new FormGroup({
            email: new FormControl(null, tslib_1.__spread([
                Validators.required
            ], Validations.getEmailRules()))
        });
        return adminFormGroup;
    };
    AdminUserModifyComponent.prototype.cancel = function () {
        this.dialogRef.close();
    };
    AdminUserModifyComponent.prototype.save = function () {
        var _this = this;
        this.withProgressBar(this.adminService.inviteAdminUser(this.form.value)).subscribe(function (user) {
            _this.dialogRef.close(user);
        });
    };
    return AdminUserModifyComponent;
}(ApiFormComponent));
export { AdminUserModifyComponent };
