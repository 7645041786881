import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { ReportType } from '../models/report.model';
import * as moment from 'moment';
import { Validators } from '../../common/forms/validators';
import { MY_FORMATS } from "../reports/reports.component";
export function customValidateArray() {
    return function (formArray) {
        var e_1, _a;
        var sum = 0;
        try {
            for (var _b = tslib_1.__values(formArray.controls), _c = _b.next(); !_c.done; _c = _b.next()) {
                var c = _c.value;
                var control = c;
                var adjControl = control.controls['adjustment'];
                sum += Number(adjControl.value);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (sum !== 0) {
            return { not_zero: true };
        }
        else {
            return null;
        }
    };
}
var ɵ0 = MY_FORMATS;
var AdjustmentComponent = /** @class */ (function () {
    function AdjustmentComponent(fb, apiResourcesService, snackBar, changeDetectorRefs) {
        this.fb = fb;
        this.apiResourcesService = apiResourcesService;
        this.snackBar = snackBar;
        this.changeDetectorRefs = changeDetectorRefs;
        this.adjustmentsArray = [];
        this.adjustmentDiff = [];
        this.displayedColumns = [
            'accountCode',
            'accountName',
            'accountType',
            'adjustment',
            'currency',
            'fx',
            'multiply',
            'adjusted',
            'date',
            'comments',
        ];
        this.companyList = [];
        this.accountsList = [];
        this.activeDate = new FormControl({ value: moment(), disabled: true });
        this.formDate = new FormControl({ value: '', disabled: true });
        this.tomorrow = new Date();
        this.tomorrow.setDate(this.tomorrow.getDate());
    }
    Object.defineProperty(AdjustmentComponent.prototype, "amountArrayControl", {
        get: function () {
            return this.form.get('amountArray');
        },
        enumerable: true,
        configurable: true
    });
    AdjustmentComponent.prototype.ngOnInit = function () {
        console.clear();
        this.formDate.setValue(moment().subtract(1, 'month').format('YYYY-MM'));
        this.createForm();
        this.getAdjustments(moment().subtract(1, 'month').format('YYYY-MM'));
        this.createFilteredForm();
    };
    AdjustmentComponent.prototype.createForm = function () {
        this.form = this.fb.group({
            amountArray: this.fb.array([], customValidateArray())
        });
    };
    AdjustmentComponent.prototype.createFilteredForm = function () {
        this.filteredForm = this.fb.group({
            accCode: new FormControl(null, []),
            accName: new FormControl(null, []),
            searchInput: new FormControl(null, []),
            companySelect: new FormControl(null, [])
        });
    };
    AdjustmentComponent.prototype.createItem = function (accId, adjustment, comment) {
        if (comment === void 0) { comment = ''; }
        return this.fb.group({
            accId: this.fb.control(accId),
            adjustment: this.fb.control(adjustment, { validators: [Validators.required], updateOn: 'blur' }),
            comment: this.fb.control(comment, [Validators.maxLength(255)])
        });
    };
    AdjustmentComponent.prototype.getAdjustments = function (date, id) {
        var _this = this;
        this.progressBarMode = 'query';
        this.apiResourcesService.reportSummaryRecord
            .whereId(date)
            .where('type', ReportType.Json)
            .first()
            .pipe(finalize(function () {
            _this.progressBarMode = null;
        }))
            .subscribe(function (reports) {
            var e_2, _a;
            if (!id) {
                id = reports.reports[0].company.id;
                _this.accountsList = reports.reports[0].accounts;
            }
            _this.companyList = [];
            _this.reportsArray = reports;
            try {
                for (var _b = tslib_1.__values(reports.reports), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var report = _c.value;
                    _this.companyList.push(report.company);
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_2) throw e_2.error; }
            }
            _this.createAccTable(id);
            _this.filteredForm.get('companySelect').setValue(id);
        }, function (error) {
            _this.dataSource = [];
            _this.adjustmentsArray = [];
        });
    };
    AdjustmentComponent.prototype.createAccTable = function (id) {
        var e_3, _a;
        this.adjustmentsArray = [];
        var arr = this.form.controls.amountArray;
        arr.clear();
        var report = this.reportsArray.reports.find(function (obj) {
            return obj.companyId === id;
        });
        try {
            for (var _b = tslib_1.__values(report.accounts), _c = _b.next(); !_c.done; _c = _b.next()) {
                var acc = _c.value;
                var adjustments = {};
                adjustments.companyId = report.companyId;
                adjustments.currency = report.currency;
                adjustments.date = report.date;
                this.date = report.date;
                if (acc.accountType.toLowerCase() === 'pl') {
                    adjustments.fx = report.midRate;
                }
                else {
                    adjustments.fx = report.endRate;
                }
                adjustments.targetCurrency = report.targetCurrency;
                adjustments.accountCode = acc.accountCode;
                adjustments.accountName = acc.accountName;
                adjustments.accountType = acc.accountType;
                adjustments.adjusted = acc.adjusted * adjustments.fx;
                adjustments.adjustment = acc.adjustment;
                adjustments.adjustmentComment = acc.adjustmentComment;
                adjustments.balance = acc.balance;
                adjustments.id = acc.id;
                adjustments.reportingCurrency = acc.reportingCurrency;
                adjustments.multiply = acc.adjustment * adjustments.fx;
                this.targetCurrency = report.targetCurrency;
                this.adjustmentsArray.push(adjustments);
                this.activeCompanyId = report.companyId;
                this.amountArray = this.form.get('amountArray');
                this.amountArray.push(this.createItem(acc.id, acc.adjustment, acc.adjustmentComment));
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_3) throw e_3.error; }
        }
        this.activeCompanyName = report.company.name;
        this.dataSource = new MatTableDataSource(this.adjustmentsArray);
        this.dataSource.filterPredicate = function (data, filter) {
            return data.accountName.toString().toLowerCase().includes(filter) || data.accountCode.toString().toLowerCase().includes(filter);
        };
        this.filteredForm.get('accName').setValue(null);
        this.filteredForm.get('accCode').setValue(null);
        this.changeDetectorRefs.detectChanges();
    };
    AdjustmentComponent.prototype.changeCompany = function (companyId) {
        this.createAccTable(companyId);
    };
    AdjustmentComponent.prototype.chooseAccCode = function () {
        this.dataSource.filter = [];
        this.filteredForm.get('searchInput').setValue(null);
        var code = this.filteredForm.get('accCode').value;
        var name = this.filteredForm.get('accName').value;
        var filteredArray;
        var codeArr = [];
        var nameArr = [];
        var arrayFindObjectByProp = function (arr, prop, val) {
            return arr.find(function (obj) { return obj[prop] == val; });
        };
        if (code) {
            for (var i = 0; i < code.length; i++) {
                codeArr.push(arrayFindObjectByProp(this.adjustmentsArray, 'accountCode', code[i]));
            }
        }
        if (name) {
            for (var i = 0; i < name.length; i++) {
                nameArr.push(arrayFindObjectByProp(this.adjustmentsArray, 'accountName', name[i]));
            }
        }
        var mergedArrays = codeArr.concat(nameArr);
        filteredArray = mergedArrays.filter(function (item, pos) { return mergedArrays.indexOf(item) === pos; });
        if (filteredArray && filteredArray.length) {
            this.dataSource.data = filteredArray;
        }
        else {
            this.clearSearchSelect();
        }
    };
    AdjustmentComponent.prototype.applyFilter = function (filterValue) {
        this.clearSearchSelect();
        filterValue = filterValue.value.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    };
    AdjustmentComponent.prototype.clearSearchSelect = function () {
        this.dataSource.data = this.adjustmentsArray;
        this.filteredForm.get('accName').setValue(null);
        this.filteredForm.get('accCode').setValue(null);
    };
    AdjustmentComponent.prototype.calculateAmount = function (target, account) {
        if (target.value && Number(+target.value)) {
            account.multiply = (+target.value) * account.fx;
            account.adjusted = (+target.value + account.reportingCurrency) * account.fx;
        }
        else {
            account.multiply = 0;
            account.adjusted = account.reportingCurrency * account.fx;
        }
    };
    AdjustmentComponent.prototype.focusOutFunction = function (target, element) {
        var e_4, _a;
        var formArray = this.form.controls.amountArray;
        if (!target.value || !Number(+target.value)) {
            try {
                for (var _b = tslib_1.__values(formArray.controls), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var c = _c.value;
                    if (c.value.accId === element.id) {
                        c.get('adjustment').setValue(0);
                    }
                }
            }
            catch (e_4_1) { e_4 = { error: e_4_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_4) throw e_4.error; }
            }
        }
    };
    AdjustmentComponent.prototype.save = function () {
        var _this = this;
        var e_5, _a;
        this.progressBarMode = 'query';
        this.adjustmentDiff = [];
        try {
            for (var _b = tslib_1.__values(this.form.value.amountArray), _c = _b.next(); !_c.done; _c = _b.next()) {
                var account = _c.value;
                this.adjustmentDiff.push({
                    account_id: account.accId,
                    adjustment: Number(account.adjustment),
                    comment: account.comment
                });
            }
        }
        catch (e_5_1) { e_5 = { error: e_5_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_5) throw e_5.error; }
        }
        this.apiResourcesService.reportAdjustment
            .where('type', ReportType.Json)
            .whereSub("/" + this.activeCompanyId)
            .update({
            accounts: this.adjustmentDiff
        }, this.date).pipe(finalize(function () {
            _this.progressBarMode = null;
        }))
            .subscribe(function () {
            _this.adjustmentDiff = [];
            _this.reportsArray = [];
            _this.getAdjustments(_this.formDate.value, _this.activeCompanyId);
            //this.getAdjustments(moment().subtract(1, 'month').format('YYYY-MM'), this.activeCompanyId);
        });
    };
    AdjustmentComponent.prototype.downloadAdj = function () {
        //.whereId(moment().subtract(1, 'month').format('YYYY-MM'))
        var link = this.apiResourcesService.reportSummaryRecord
            .whereId(this.formDate.value)
            .where('type', ReportType.XlsxAdjustment)
            .authorizeUrl().url;
        window.open(link);
    };
    AdjustmentComponent.prototype.chosenYearHandler = function (normalizedYear) {
        var ctrlValue = this.activeDate.value;
        ctrlValue.year(normalizedYear.year());
        this.formDate.setValue(ctrlValue.format('YYYY-MM'));
    };
    AdjustmentComponent.prototype.chosenMonthHandler = function (normalizedMonth, datepicker) {
        var ctrlValue = this.activeDate.value;
        ctrlValue.month(normalizedMonth.month());
        this.formDate.setValue(ctrlValue.format('YYYY-MM'));
        this.getAdjustments(ctrlValue.format('YYYY-MM'));
        this.createFilteredForm();
        datepicker.close();
    };
    AdjustmentComponent.prototype.closePicker = function () {
        this.getAdjustments(this.formDate.value);
        this.createFilteredForm();
    };
    return AdjustmentComponent;
}());
export { AdjustmentComponent };
export { ɵ0 };
