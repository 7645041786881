import { Routes } from '@angular/router';
import { UserGuard } from '../../common/auth/user.guard';
import { DashboardComponent } from '../user/dashboard.component';
import { ReportsComponent } from './reports.component';
import { CreateReportComponent } from './create-report/create-report.component';
import { homeOrLogin } from '../auth/home-or-login';
import { UserRole } from '../models/user.model';
import { roleMatcher } from '../auth/role.matcher';
import { ReportsDetailComponent } from './reports-detail/reports-detail.component';
import { GeneralReportComponent } from './general-report/general-report.component';
var ɵ0 = {
    authUserMatcher: roleMatcher([UserRole.MasterUser, UserRole.User]),
    authFallbackUrl: homeOrLogin
};
var routes = [
    {
        path: 'reports',
        component: DashboardComponent,
        canActivate: [UserGuard],
        data: ɵ0,
        children: [
            {
                path: '',
                component: ReportsComponent,
            },
            {
                path: 'create-report/:companyId',
                component: CreateReportComponent,
            },
            {
                path: 'general-report',
                component: GeneralReportComponent,
            },
            {
                path: 'details/:companyId/:date',
                component: ReportsDetailComponent,
            }
        ]
    }
];
var ReportsRoutingModule = /** @class */ (function () {
    function ReportsRoutingModule() {
    }
    return ReportsRoutingModule;
}());
export { ReportsRoutingModule };
export { ɵ0 };
