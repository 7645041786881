import * as tslib_1 from "tslib";
import { BehaviorSubject, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { LocalStorage } from '../local.storage';
import { persistence } from '../rxjs/operators/persistence';
var AuthService = /** @class */ (function () {
    function AuthService() {
        this.userSubject = new BehaviorSubject(undefined);
        this.createStorage();
    }
    Object.defineProperty(AuthService.prototype, "token", {
        get: function () {
            return this.storage.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "user", {
        get: function () {
            var _this = this;
            return this.userSubject
                .pipe(persistence(
            // if no user, but token -> fetch user
            function (user) { return !user && !!_this.token; }, function () { return _this.getUser(_this.token); }));
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.createStorage = function () {
        this.storage = new LocalStorage('app:auth');
    };
    AuthService.prototype.remember = function (token, user) {
        this.storage.set(token);
        this.userSubject.next(user || undefined);
        return of(null);
    };
    AuthService.prototype.forget = function () {
        this.storage.unset();
        this.userSubject.next(undefined);
        return of(null);
    };
    AuthService.prototype.check = function () {
        return this.user
            .pipe(map(function (u) { return !!u; }));
    };
    AuthService.prototype.guest = function () {
        return this.check()
            .pipe(map(function (isUser) { return !isUser; }));
    };
    AuthService.prototype.attempt = function (credentials) {
        var _this = this;
        return this.forget()
            .pipe(switchMap(function () { return _this.performAttempt(credentials); }), switchMap(function (_a) {
            var _b = tslib_1.__read(_a, 2), token = _b[0], user = _b[1];
            return _this.remember(token, user);
        }), switchMap(function () { return _this.user; }));
    };
    AuthService.prototype.revoke = function () {
        var _this = this;
        if (!this.token) {
            return this.forget();
        }
        return this.performRevoke(this.token)
            .pipe(switchMap(function () { return _this.forget(); }));
    };
    return AuthService;
}());
export { AuthService };
