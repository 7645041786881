import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { ReportType } from '../../models/report.model';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Validators } from '../../../common/forms/validators';
import { finalize } from 'rxjs/operators';
var UploadReportModalComponent = /** @class */ (function () {
    function UploadReportModalComponent(dialogRef, apiResourcesService, apiService, data) {
        this.dialogRef = dialogRef;
        this.apiResourcesService = apiResourcesService;
        this.apiService = apiService;
        this.subscriptions = new Subscription();
        this.messages = [];
        this.companyId = data.companyId;
    }
    UploadReportModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.control = new FormControl(null, [Validators.required]);
        this.subscriptions.add(this.apiService.on(422).subscribe(function (response) {
            var e_1, _a, e_2, _b;
            _this.messages = [];
            if (response.errors) {
                try {
                    for (var _c = tslib_1.__values(Object.keys(response.errors)), _d = _c.next(); !_d.done; _d = _c.next()) {
                        var key = _d.value;
                        try {
                            for (var _e = tslib_1.__values(response.errors[key]), _f = _e.next(); !_f.done; _f = _e.next()) {
                                var err = _f.value;
                                _this.messages.push(err);
                            }
                        }
                        catch (e_2_1) { e_2 = { error: e_2_1 }; }
                        finally {
                            try {
                                if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                            }
                            finally { if (e_2) throw e_2.error; }
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
        }));
    };
    UploadReportModalComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    UploadReportModalComponent.prototype.upload = function () {
        var _this = this;
        var file = this.control.value[0];
        var form = new FormData();
        form.append('accounts', file, 'report');
        this.progressBarMode = 'indeterminate';
        this.control.disable();
        this.apiResourcesService.reportBalance
            .whereId(moment().subtract(1, 'month').format('YYYY-MM') + '/' + this.companyId)
            .where('type', ReportType.Xlsx)
            .where('_method', 'PUT')
            .create(form)
            .pipe(finalize(function () {
            _this.progressBarMode = undefined;
            _this.control.enable();
        }))
            .subscribe(function () { return _this.dialogRef.close(); });
    };
    UploadReportModalComponent.prototype.cancel = function () {
        this.dialogRef.close();
    };
    return UploadReportModalComponent;
}());
export { UploadReportModalComponent };
