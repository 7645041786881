import * as tslib_1 from "tslib";
import { ApiFormComponent } from '../forms/api-form.component';
import { FormControl, FormGroup } from '@angular/forms';
import { Validators } from '../../common/forms/validators';
import { Validations } from '../forms/validations';
import { MatDialogRef } from '@angular/material';
import { omit } from 'lodash';
var UserProfileComponent = /** @class */ (function (_super) {
    tslib_1.__extends(UserProfileComponent, _super);
    function UserProfileComponent(dialogRef, apiResourcesService, apiService, user) {
        var _this = _super.call(this, apiService) || this;
        _this.dialogRef = dialogRef;
        _this.apiResourcesService = apiResourcesService;
        _this.user = user;
        return _this;
    }
    UserProfileComponent.prototype.createForm = function () {
        var formGroup = new FormGroup(tslib_1.__assign({ email: new FormControl(null, tslib_1.__spread([
                Validators.required
            ], Validations.getEmailRules())), first_name: new FormControl(null, [
                Validators.required,
                Validators.maxLength(50)
            ]), last_name: new FormControl(null, [
                Validators.required,
                Validators.maxLength(50)
            ]) }, Validators.withConfirmation(new FormControl(null, tslib_1.__spread(Validations.getPasswordRules())), 'password', this.subscriptions)));
        formGroup.addControl.apply(formGroup, tslib_1.__spread(Validators.passworded(formGroup.get('password'), 'password', this.subscriptions, Validations.getPasswordRules())));
        return formGroup;
    };
    UserProfileComponent.prototype.updateFormValue = function () {
        this.form.patchValue(this.user.toObject());
    };
    Object.defineProperty(UserProfileComponent.prototype, "updateValue", {
        get: function () {
            var value = this.form.value;
            if (!value.password) {
                return omit(value, ['password', 'password_confirmation', 'password_passworded']);
            }
            return value;
        },
        enumerable: true,
        configurable: true
    });
    UserProfileComponent.prototype.cancel = function () {
        this.dialogRef.close();
    };
    UserProfileComponent.prototype.save = function () {
        var _this = this;
        this.withProgressBar(this.apiResourcesService.auth.user.update(this.updateValue))
            .subscribe(function (user) { return _this.dialogRef.close(user); });
    };
    return UserProfileComponent;
}(ApiFormComponent));
export { UserProfileComponent };
