import { Routes } from '@angular/router';
import { BlankLayoutComponent } from '../ui/blank-layout.component';
import { GuestGuard } from '../../common/auth/guest.guard';
import { LoginComponent } from './login.component';
import { JoinComponent } from './join.component';
import { homeOrLogin } from '../auth/home-or-login';
var ɵ0 = {
    authFallbackUrl: homeOrLogin
};
var routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    {
        path: '',
        component: BlankLayoutComponent,
        canActivate: [GuestGuard],
        data: ɵ0,
        children: [
            {
                path: 'login',
                component: LoginComponent
            },
            {
                path: 'join/:token',
                component: JoinComponent
            }
        ]
    }
];
var GuestRoutingModule = /** @class */ (function () {
    function GuestRoutingModule() {
    }
    return GuestRoutingModule;
}());
export { GuestRoutingModule };
export { ɵ0 };
