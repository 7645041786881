import * as tslib_1 from "tslib";
import { Validators } from '../../../common/forms/validators';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiFormComponent } from '../../forms/api-form.component';
import { MatDialogRef } from '@angular/material/dialog';
var AccountModifyComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AccountModifyComponent, _super);
    function AccountModifyComponent(dialogRef, apiResourcesService, apiService, data) {
        var _this = _super.call(this, apiService) || this;
        _this.dialogRef = dialogRef;
        _this.apiResourcesService = apiResourcesService;
        _this.types = ['BS', 'PL'];
        _this.disableBtn = false;
        _this.account = data && data.account;
        _this.title = data && data.title || (_this.isNew ? 'Create account' : 'Edit account');
        return _this;
    }
    Object.defineProperty(AccountModifyComponent.prototype, "accountId", {
        get: function () {
            return this.account && this.account.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountModifyComponent.prototype, "isNew", {
        get: function () {
            return !this.accountId;
        },
        enumerable: true,
        configurable: true
    });
    AccountModifyComponent.prototype.createForm = function () {
        var formGroup = new FormGroup({
            code: new FormControl(null, [
                Validators.required,
                Validators.maxLength(10)
            ]),
            name: new FormControl(null, [
                Validators.required,
                Validators.maxLength(50)
            ]),
            type: new FormControl(null, [
                Validators.required
            ]),
            local_mapping: new FormControl(null, [
                Validators.maxLength(50)
            ]),
            group_mapping: new FormControl(null, [
                Validators.maxLength(50)
            ])
        });
        return formGroup;
    };
    AccountModifyComponent.prototype.updateFormValue = function () {
        if (!this.isNew) {
            this.form.patchValue(this.account.toObject());
        }
    };
    AccountModifyComponent.prototype.cancel = function () {
        this.dialogRef.close();
    };
    AccountModifyComponent.prototype.save = function () {
        var _this = this;
        this.form.value.code = +this.form.value.code;
        this.disableBtn = true;
        this.withProgressBar(this.apiResourcesService.account[this.isNew ? 'create' : 'update'](this.form.value, this.accountId))
            .subscribe(function (account) {
            _this.dialogRef.close(account);
            _this.disableBtn = false;
        }, function (error) {
            _this.disableBtn = false;
        });
    };
    return AccountModifyComponent;
}(ApiFormComponent));
export { AccountModifyComponent };
