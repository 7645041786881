import * as tslib_1 from "tslib";
import { BaseModel } from '../../common/model/base.model';
import { Property } from '../../common/model/property.decorator';
var ReportAccountModel = /** @class */ (function (_super) {
    tslib_1.__extends(ReportAccountModel, _super);
    function ReportAccountModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    tslib_1.__decorate([
        Property({
            sourceName: 'account_id'
        })
    ], ReportAccountModel.prototype, "id", void 0);
    tslib_1.__decorate([
        Property({
            sourceName: 'account_code'
        })
    ], ReportAccountModel.prototype, "accountCode", void 0);
    tslib_1.__decorate([
        Property({
            sourceName: 'account_name'
        })
    ], ReportAccountModel.prototype, "accountName", void 0);
    tslib_1.__decorate([
        Property({
            sourceName: 'account_type'
        })
    ], ReportAccountModel.prototype, "accountType", void 0);
    tslib_1.__decorate([
        Property()
    ], ReportAccountModel.prototype, "balance", void 0);
    tslib_1.__decorate([
        Property()
    ], ReportAccountModel.prototype, "adjusted", void 0);
    tslib_1.__decorate([
        Property()
    ], ReportAccountModel.prototype, "adjustment", void 0);
    tslib_1.__decorate([
        Property({
            sourceName: 'reporting_currency'
        })
    ], ReportAccountModel.prototype, "reportingCurrency", void 0);
    tslib_1.__decorate([
        Property({
            sourceName: 'adjustment_comment'
        })
    ], ReportAccountModel.prototype, "adjustmentComment", void 0);
    return ReportAccountModel;
}(BaseModel));
export { ReportAccountModel };
