import * as i0 from "@angular/core";
import * as i1 from "../api/api-resources.service";
var CompanyService = /** @class */ (function () {
    function CompanyService(apiResourcesService) {
        this.apiResourcesService = apiResourcesService;
    }
    CompanyService.prototype.getCompany = function (id) {
        return this.apiResourcesService.company.find(id);
    };
    CompanyService.prototype.getCompanies = function () {
        return this.apiResourcesService.company.get();
    };
    CompanyService.prototype.createCompany = function (company) {
        return this.apiResourcesService.company.create(company);
    };
    CompanyService.prototype.editCompany = function (id, company) {
        return this.apiResourcesService.company.update(company, id);
    };
    CompanyService.prototype.deleteCompany = function (id) {
        return this.apiResourcesService.company.delete(id);
    };
    CompanyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CompanyService_Factory() { return new CompanyService(i0.ɵɵinject(i1.ApiResourcesService)); }, token: CompanyService, providedIn: "root" });
    return CompanyService;
}());
export { CompanyService };
