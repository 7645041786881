import { EventEmitter } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material';
import { NestedTreeControl } from '@angular/cdk/tree';
var CompaniesListComponent = /** @class */ (function () {
    function CompaniesListComponent(dialog) {
        this.dialog = dialog;
        this.roots = [];
        this.add = new EventEmitter();
        this.edit = new EventEmitter();
        this.delete = new EventEmitter();
        this.treeControl = new NestedTreeControl(function (node) { return node.children; });
        this.dataSource = new MatTreeNestedDataSource();
        this.hasChild = function (_, node) { return !!node.children && node.children.length > 0; };
    }
    Object.defineProperty(CompaniesListComponent.prototype, "companies", {
        set: function (companies) {
            this.roots = this.makeTrees(companies.map(function (company) { return company.clone(); }));
            this.initTree(this.roots);
        },
        enumerable: true,
        configurable: true
    });
    CompaniesListComponent.prototype.initTree = function (data) {
        this.dataSource.data = data;
    };
    CompaniesListComponent.prototype.makeTrees = function (companies) {
        var notRoots = [];
        companies.forEach(function (company) {
            if (company.parentId) {
                var parent_1 = companies.find(function (other) { return other.id === company.parentId; });
                if (parent_1) {
                    if (!Array.isArray(parent_1.children)) {
                        parent_1.children = [];
                    }
                    parent_1.children.push(company);
                    notRoots.push(company.id);
                }
            }
        });
        return companies.filter(function (company) { return notRoots.indexOf(company.id) === -1; });
    };
    CompaniesListComponent.prototype.onAdd = function (parent) {
        this.add.emit(parent);
    };
    CompaniesListComponent.prototype.onEdit = function (company) {
        this.edit.emit(company);
    };
    CompaniesListComponent.prototype.onDelete = function (company) {
        this.delete.emit(company);
    };
    return CompaniesListComponent;
}());
export { CompaniesListComponent };
