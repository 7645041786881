import * as i0 from "@angular/core";
import * as i1 from "../api/api-resources.service";
var AdminService = /** @class */ (function () {
    function AdminService(apiResourcesService) {
        this.apiResourcesService = apiResourcesService;
    }
    AdminService.prototype.getAdminUser = function (id) {
        return this.apiResourcesService.authAdmin.user.find(id);
    };
    AdminService.prototype.getAdminUsers = function (n, size) {
        return this.apiResourcesService.authAdmin.user.page(n, size).get();
    };
    AdminService.prototype.inviteAdminUser = function (data) {
        return this.apiResourcesService.authAdmin.user.create(data);
    };
    AdminService.prototype.updateAdminUser = function (data) {
        return this.apiResourcesService.authAdmin.user.update(data);
    };
    AdminService.prototype.deleteAdminUser = function (id) {
        return this.apiResourcesService.authAdmin.user.delete(id);
    };
    AdminService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminService_Factory() { return new AdminService(i0.ɵɵinject(i1.ApiResourcesService)); }, token: AdminService, providedIn: "root" });
    return AdminService;
}());
export { AdminService };
