import * as tslib_1 from "tslib";
import { FormControl, FormGroup } from '@angular/forms';
import { Validators } from '../../common/forms/validators';
import { Validations } from '../forms/validations';
import { ApiFormComponent } from '../forms/api-form.component';
var JoinComponent = /** @class */ (function (_super) {
    tslib_1.__extends(JoinComponent, _super);
    function JoinComponent(router, route, apiResourcesService, apiService) {
        var _this = _super.call(this, apiService) || this;
        _this.router = router;
        _this.route = route;
        _this.apiResourcesService = apiResourcesService;
        return _this;
    }
    JoinComponent.prototype.createForm = function () {
        return new FormGroup(tslib_1.__assign({ invite_key: new FormControl(this.route.snapshot.paramMap.get('token'), [
                Validators.required
            ]), first_name: new FormControl(null, [
                Validators.required,
                Validators.maxLength(50)
            ]), last_name: new FormControl(null, [
                Validators.required,
                Validators.maxLength(50)
            ]) }, Validators.withConfirmation(new FormControl(null, tslib_1.__spread([
            Validators.required
        ], Validations.getPasswordRules())), 'password', this.subscriptions)));
    };
    JoinComponent.prototype.join = function () {
        var _this = this;
        this.withProgressBar(this.apiResourcesService.auth.user.create(this.form.value))
            .subscribe(function () { return _this.router.navigate(['/login']); });
    };
    return JoinComponent;
}(ApiFormComponent));
export { JoinComponent };
