import * as tslib_1 from "tslib";
import { AuthService as CommonAuthService } from '../../common/auth/auth.service';
import { AuthTokenModel } from '../models/auth-token.model';
import { LocalStorage } from '../../common/local.storage';
import { map, switchMap } from 'rxjs/operators';
var AuthService = /** @class */ (function (_super) {
    tslib_1.__extends(AuthService, _super);
    function AuthService(router, apiService, apiResourcesService) {
        var _this = _super.call(this) || this;
        _this.router = router;
        _this.apiService = apiService;
        _this.apiResourcesService = apiResourcesService;
        _this.apiService.authTokenGetter = function () { return "" + _this.token; };
        _this.apiService
            .on(401)
            .pipe(switchMap(function () { return _this.forget(); }))
            .subscribe(function () { return _this.router.navigateByUrl('/login'); });
        return _this;
    }
    AuthService.prototype.getUser = function (token) {
        return this.apiResourcesService.auth.user.first();
    };
    AuthService.prototype.performAttempt = function (credentials) {
        return this.apiResourcesService.auth.access.create(credentials).pipe(map(function (access) { return [access.token, access.user]; }));
    };
    AuthService.prototype.performRevoke = function (token) {
        return this.apiResourcesService.auth.access.delete();
    };
    AuthService.prototype.createStorage = function () {
        this.storage = new LocalStorage('app:auth', function (attributes) {
            if (attributes) {
                return new AuthTokenModel(attributes);
            }
        });
    };
    return AuthService;
}(CommonAuthService));
export { AuthService };
