import * as i from 'inflect';
import { copy, isFunction } from '../utils';
import { propertyFactoryGetter } from './property.decorator';
var BaseModel = /** @class */ (function () {
    function BaseModel(attributes) {
        this.attributes = {};
        if (attributes) {
            this.fill(attributes);
        }
    }
    Object.defineProperty(BaseModel, "idName", {
        get: function () {
            return 'id';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseModel.prototype, "idName", {
        get: function () {
            return this.constructor.idName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseModel.prototype, "idValue", {
        get: function () {
            return this.getAttribute(this.idName);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseModel.prototype, "exists", {
        get: function () {
            return !!this.idValue;
        },
        enumerable: true,
        configurable: true
    });
    BaseModel.prototype.makeMutatorName = function (type, name) {
        return "" + type + i.camelize(i.underscore(name)) + "Attribute";
    };
    BaseModel.prototype.getMutatorIfExists = function (type, name) {
        var mutator = this.makeMutatorName(type, name);
        return typeof this[mutator] === 'function' ? mutator : null;
    };
    BaseModel.prototype.getTypeFactoryIfExists = function (name) {
        var factory = this[propertyFactoryGetter(name)];
        if (isFunction(factory)) {
            return factory;
        }
    };
    BaseModel.prototype.getRawAttribute = function (name) {
        if (this.attributes.hasOwnProperty(name)) {
            return this.attributes[name];
        }
    };
    BaseModel.prototype.getAttribute = function (name) {
        var mutator = this.getMutatorIfExists('get', name);
        var value = this.getRawAttribute(name);
        if (mutator) {
            value = this[mutator](value);
        }
        return value;
    };
    BaseModel.prototype.setAttribute = function (name, value) {
        var mutator = this.getMutatorIfExists('set', name);
        var typeFactory = this.getTypeFactoryIfExists(name);
        if (typeFactory) {
            value = typeFactory(value);
        }
        if (mutator) {
            value = this[mutator](value);
        }
        this.attributes[name] = value;
        return this;
    };
    BaseModel.prototype.get = function (name) {
        return this.getAttribute(name);
    };
    BaseModel.prototype.set = function (name, value) {
        return this.setAttribute(name, value);
    };
    BaseModel.prototype.fill = function (attributes) {
        var _this = this;
        Object.keys(attributes)
            .forEach(function (name) { return _this.setAttribute(name, attributes[name]); });
        return this;
    };
    BaseModel.prototype.fillFrom = function (other, only) {
        return this.fill(other.toObject(only));
    };
    BaseModel.prototype.equal = function (other) {
        return other instanceof this.constructor && other.idValue === this.idValue;
    };
    BaseModel.prototype.clone = function (only) {
        return new this.constructor(copy(this.toObject(only)));
    };
    BaseModel.prototype.twin = function (attributes) {
        var clone = this.clone([this.idName]);
        if (attributes) {
            clone.fill(attributes);
        }
        return clone;
    };
    BaseModel.prototype.toObject = function (only) {
        var _this = this;
        if (only === void 0) { only = []; }
        return (only.length
            ? Object.keys(this.attributes).filter(function (name) { return only.includes(name); })
            : Object.keys(this.attributes)).reduce(function (obj, name) {
            obj[name] = _this.getAttribute(name);
            return obj;
        }, {});
    };
    BaseModel.prototype.toJSON = function () {
        return this.toObject();
    };
    BaseModel.prototype.valueOf = function () {
        return this.idValue;
    };
    BaseModel.prototype.toString = function () {
        return "" + this.valueOf();
    };
    return BaseModel;
}());
export { BaseModel };
