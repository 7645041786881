import { EmbeddedViewRef, KeyValueChangeRecord, KeyValueChanges, KeyValueDiffer, KeyValueDiffers, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { isPresent } from '../utils';
var ErrorsOfContext = /** @class */ (function () {
    function ErrorsOfContext(control, validator, $implicit, context) {
        this.control = control;
        this.validator = validator;
        this.$implicit = $implicit;
        this.context = context;
    }
    return ErrorsOfContext;
}());
export { ErrorsOfContext };
var ErrorsDirective = /** @class */ (function () {
    function ErrorsDirective(viewContainer, template, differs, validationRenderer) {
        this.viewContainer = viewContainer;
        this.template = template;
        this.differs = differs;
        this.validationRenderer = validationRenderer;
        this.subscriptions = new Subscription();
        this.changesViews = {};
    }
    Object.defineProperty(ErrorsDirective.prototype, "errorsOf", {
        set: function (control) {
            this.control = control;
        },
        enumerable: true,
        configurable: true
    });
    ErrorsDirective.prototype.getChangeView = function (record) {
        if (isPresent(this.changesViews[record.key])) {
            return this.changesViews[record.key];
        }
    };
    ErrorsDirective.prototype.removeChangeView = function (record) {
        if (isPresent(this.changesViews[record.key])) {
            this.viewContainer.remove(this.viewContainer.indexOf(this.changesViews[record.key]));
        }
    };
    ErrorsDirective.prototype.createChangeView = function (record) {
        this.changesViews[record.key] = this.viewContainer
            .createEmbeddedView(this.template, new ErrorsOfContext(this.errorsSubject, record.key, this.validationRenderer
            .render(this.errorsSubject, record.key, record.currentValue), record.currentValue));
    };
    ErrorsDirective.prototype.updateChangeView = function (record) {
        var view = this.getChangeView(record);
        view.context.$implicit = this.validationRenderer.render(this.errorsSubject, record.key, record.currentValue);
        view.context.context = record.currentValue;
    };
    ErrorsDirective.prototype.applyChanges = function (changes) {
        var _this = this;
        changes.forEachAddedItem(function (record) { return _this.createChangeView(record); });
        changes.forEachChangedItem(function (record) { return _this.updateChangeView(record); });
        changes.forEachRemovedItem(function (record) { return _this.removeChangeView(record); });
    };
    ErrorsDirective.prototype.doCheck = function (errors) {
        if (!this.differ) {
            this.differ = this.differs.find(errors).create();
        }
        var changes = this.differ.diff(errors);
        if (changes) {
            this.applyChanges(changes);
        }
    };
    ErrorsDirective.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.add(this.control
            .statusChanges
            .pipe(startWith(null), map(function () { return _this.control.errors || {}; }))
            .subscribe(function (errors) { return _this.doCheck(errors); }));
    };
    ErrorsDirective.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    return ErrorsDirective;
}());
export { ErrorsDirective };
